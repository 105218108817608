(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navMain = navMain;
function navMain() {
  var hamIcon = document.getElementById("ham-icon");
  var navMain = document.getElementById("nav-main");
  var closeBtn = document.getElementById("close-btn");

  // Check if navMain exists before attaching event listeners
  if (navMain && closeBtn) {
    // Handle click and touchstart events for hamburger icon
    var toggleNav = function toggleNav() {
      // Toggle the "show" class on navMain
      navMain.classList.toggle("show");
    };
    hamIcon.addEventListener("click", toggleNav);
    hamIcon.addEventListener("touchstart", toggleNav);

    // Handle keyboard accessibility for ham-icon
    hamIcon.addEventListener("keydown", function (event) {
      if (event.key === "Enter" || event.key === " ") {
        // Trigger the same action as the click event for keyboard users
        toggleNav();
      }
    });

    // Handle click event for close button
    closeBtn.addEventListener("click", function () {
      // Remove the "show" class to hide the navigation
      navMain.classList.remove("show");
    });

    // Handle keyboard accessibility for closeBtn
    closeBtn.addEventListener("keydown", function (event) {
      if (event.key === "Enter" || event.key === " ") {
        // Trigger the same action as the click event for keyboard users
        navMain.classList.remove("show");
      }
    });
  }
}

},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.smoothScroll = smoothScroll;
function smoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      var targetElement = document.querySelector(this.getAttribute("href"));
      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth"
        });
      }
    });
  });
}

},{}],3:[function(require,module,exports){
"use strict";

var _smoothScroll = require("./global/smooth-scroll.js");
var _navMain = require("./global/nav-main.js");
//Function calls
//global
(0, _navMain.navMain)();
(0, _smoothScroll.smoothScroll)();

},{"./global/nav-main.js":1,"./global/smooth-scroll.js":2}]},{},[3]);
